@import "https://js.arcgis.com/4.29/@arcgis/core/assets/esri/themes/dark/main.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  min-height: 100%;
  font-size: 22px;
}

body {
  min-height: 100vh;
  color: white;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 150%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Hind Madurai';
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 98px;
  text-transform: uppercase;
  color: #FFFFFF;
}

h2 {
  font-family: 'Hind Madurai';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 65px;
  color: #FFFFFF;
}

h3 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 41px;
  color: #FFFFFF;
}

h4 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 48px;
  color: #FFFFFF;
}

h5 {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}

a.button {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  text-decoration: none;
}



@font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:300;
  src:local("Hind Madurai Light"),
  local("HindMadurai-Light"),
  url("./styles/fonts/HindMadurai-Light.ttf") format("truetype");
 }
 @font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:400;
  src:local("Hind Madurai"),
  local("HindMadurai"),
  url("./styles/fonts/HindMadurai-Regular.ttf") format("truetype");
 }
 @font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:500;
  src:local("Hind Madurai Medium"),
  local("HindMadurai-Medium"),
  url("./styles/fonts/HindMadurai-Medium.ttf") format("truetype");
 }
 @font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:600;
  src:local("Hind Madurai Semibold"),
  local("HindMadurai-Semibold"),
  url("./styles/fonts/HindMadurai-SemiBold.ttf") format("truetype");
 }
 @font-face {
  font-display:auto;
  font-family:Hind Madurai;
  font-style:normal;
  font-weight:700;
  src:local("Hind Madurai Bold"),
  local("HindMadurai-Bold"),
  url("./styles/fonts/HindMadurai-Bold.ttf") format("truetype");
 }

 @font-face {
  font-display:auto;
  font-family:Work Sans;
  font-style:normal;
  font-weight:300;
  src:local("Work Sans Light"),
  local("WorkSans-Light"),
  url("./styles/fonts/WorkSans-Light.ttf") format("truetype");
 }

 @font-face {
  font-display:auto;
  font-family:Work Sans;
  font-style:normal;
  font-weight:400;
  src:local("Work Sans Regular"),
  local("WorkSans-Regular"),
  url("./styles/fonts/WorkSans-Regular.ttf") format("truetype");
 }

 @font-face {
  font-display:auto;
  font-family:Work Sans;
  font-style: italic;
  font-weight:200;
  src:local("Work Sans LightItalic"),
  local("WorkSans-LightItalic"),
  url("./styles/fonts/WorkSans-LightItalic.ttf") format("truetype");
 }

 @font-face {
  font-display:auto;
  font-family:Work Sans;
  font-style: italic;
  font-weight:500;
  src:local("Work Sans Italic"),
  local("WorkSans-SemiBoldItalic"),
  url("./styles/fonts/WorkSans-SemiBoldItalic.ttf") format("truetype");
 }

@media only screen and (min-width: 1280px) {
  body {
    font-size: 22px;
  }
}

 @media (max-width: 1280px) 
 {
    html
    {
        font-size: 14px; 
    }
    h1
    {
        font-size:58px;
    }
    h2
    {
        font-size:34px;
    }
    h3
    { 
      font-size:22px;
    }
    h4
    { 
      font-size:18px;
    }
}

@media (max-width: 1408px) and (min-width: 1281px)
 {
    html
    {
        font-size: 18px; 
    }
    h1
    {
        font-size:64px;
    }
    h2
    {
        font-size:40px;
    }
    h3
    { 
      font-size:25px;
    }
    h4
    { 
      font-size:20px;
    }
}

@media (max-width: 1600px) and (min-width: 1409px)
 {
    html
    {
        font-size: 20px; 
    }
    h1
    {
        font-size:65px;
    }
    h2
    {
        font-size:45px;
    }
    h3
    { 
      font-size:29px;
    }
    h4
    { 
      font-size:22px;
    }
}


@media only screen and (min-width: 1280px) {
  h4 {
    font-size: 32px;
  }
}

