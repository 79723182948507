.menu-root {
    display: grid;
    grid-template-rows: 7.5% 50%;
    height: 100%;
    color: white
}

.menu-head {
    font-size: 16px;
    margin-left: 1rem;
    margin-right: 0.2rem;
    height: 60px;
    display: flex;
    align-items: center;
}
.menu-head > * + * {
    padding-left: 1rem;
    float: left;
}

.menu-navlist {
    display: grid;
    grid-template-rows: repeat(auto-fill, 13%);
    justify-items: stretch;
}

.menu-link {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.04em;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
    padding: 1rem;
    color: white;
    width: 100%;
}
.menu-link:hover {
    transition: all 0.2s ease-in-out;
    background: #FFF;
    color: rgba(1, 16, 51, 0.9);
}

.closeBtn:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.menu-logocontainer {
    display: flex;
}

.menu-logocontainer .avistep-logo {
    height: 26px;
	width: 104px;
	margin-top: auto;
	margin-bottom: 9px;
}