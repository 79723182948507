

.sensitivity-root {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 1fr 1fr 4fr 2fr 2fr;
    row-gap: 0.75rem;
}

.sensitivity-root .header {
    display: grid;
    grid-template-columns: 20% auto;
    text-align: left;
    align-items: center;
    justify-items: start;
    padding: 0px;
}

.sensitivity-root .header .backnav {
    height: 34px;
    width: 34px;
    border-radius: 17px;
    border: 1px solid #2E4170;
    display: grid;
    align-items: center;
    justify-items: center;
    
}
.sensitivity-root .header .backnav:hover {
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: 0 0 10px 1px #506B9C;
}

.sensitivity-root .header .title {
    display: grid;
    align-items: center;
    justify-items: start;
    text-align: left;
}

.header .title .title1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 0.5rem;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #A1BBF7;
    line-height: 1.0rem;
}

.header .title .title2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 0.9rem;
    line-height: 150%;
    color: #FFFFFF;
}

.sensitivity-root .desc {
    display: grid;
    justify-items: start;
    align-content: center;
}

.sensitivity-root .cards {
    display: grid;
    width: 100%;
    grid-template-rows: auto;
    row-gap: 0.5rem;
}

.sensitivity-root .suitable {
    display: grid;
    gap: 0.8rem;
    align-items: center;
    justify-content: left;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 100%;
    color: #A1BBF6;
    grid-template-columns: 70% auto;
    text-align: left;
}


.sensitivity-root .transparency {
    text-align: left;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #A1BBF6;
}

.sensitivity-card {
    display: grid;
    width: 100%;
    background: rgba(16, 38, 70, 0.6);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 8px;
    text-align: left;
    grid-template-columns: 8px 60% 2fr 2fr 1fr;
}


.sensitivity-card .labels {
    display: grid;
    align-content: center;
    align-items: center;
    justify-items: start;
    text-align: left;
    padding-left: 0.75rem;
    height: 3rem;
}

.sensitivity-card .labels .label1 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.9rem;
    color: #A1BBF7;
    opacity: 0.9;
    
}

.sensitivity-card .labels .label2 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 1rem;
    color: #FFFFFF;
    
}

.sensitivity-card .percent {
    display: grid;
    align-items: center;
    justify-items: start;
    text-align: left;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #FFFFFF;
}

.sensitivity-card .toggle {
    display: grid;
    align-content: center;
    align-items: center;
    justify-items: start;
    text-align: left;
}


.bodytext3 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 1rem;
    color: #8095C6;
    text-align: left;

}

.slider .MuiSlider-thumb {
    width: 19px;
    height: 19px;
    background: #A1BBF7;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
    margin-top: -8px;
    margin-left: -8px;
}

.slider .MuiSlider-rail {
    width: 100%;
    height: 5px;
    background: #27406D;
    border-radius: 16px;
}

.slider .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 3px rgba(63, 81, 181, 0.2);
}