
.wrapper {
  height: 100%;
}

.content {
  background-color: #0F2044; /* For browsers that do not support gradients */
  height: 100%;
}





  