.right-panel {
    z-index: 3;
    width: 22vw;
    min-width: 220px;
    position: absolute;
    top: 1vh;
    bottom: 2vh;
    right: 1vh;
    height: 97vh;
    background-color: rgba(1, 16, 51, 0.93);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
}

.right-panel .top-section {
    position: relative;
    height: 26vh;
    min-height: 180px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.right-panel .top-section .location {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    font-size: 0.6rem;
    font-weight: 600;
    line-height: 0.6rem;
    letter-spacing: 0.07em;
    text-align: left;
    vertical-align: middle;
    height: 0.6rem;
    margin-right: 0.5rem;
    display: flex;
}

.right-panel .top-section .location .location-icon {
    position: relative;
    height: 100%;
    display: inline-block;
    margin-right: 5px;
}

.right-panel .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.right-panel .print-icon {
    width: 50% !important;
}

.right-panel .location-icon {
    width: 9px;
    height: 11px;
}

.right-panel .top-section .share {
    position: absolute;
    top: 20px;
    left: 250px;
    background-color: #243D78;
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel > div.top-section > img {
    position: relative;
    padding: 0;
    width: 22vw;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.right-panel .top-section .print {
    position: absolute;
    top: 20px;
    left: 292px;
    background-color: #243D78;
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel .top-section .collapse {
    position: absolute;
    top: 19px;
    left: 336px;
    background-color: rgba(1, 16, 51, 0.93);
    height: 34px;
    width: 34px;
    border-radius: 17px;
}

.right-panel .top-section .title {
    position: absolute;
    top: 14vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.6rem;
    font-weight: 600;
    line-height: 0.6rem;
    letter-spacing: 0.205em;
    text-align: center;
    text-transform: uppercase;    
}

.right-panel .top-section .sub-title {
    position: absolute;
    top: 15vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.right-panel .sensitivity-score {
/*    position: absolute;
    top: 23vh;
    */
    position: relative;
    top: -30px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    border-radius: 8px;
    background: #102646;
    text-align: center;
    z-index: 4;
    right: 0;
}

.right-panel .sentitivity-score-title {
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.5rem;
    letter-spacing: 0em;
    color: #8095C6;
    margin: auto;
    margin-top: 0.5rem;
}

.right-panel .sentitivity-score-value {
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.3rem;
    letter-spacing: 0em;
    margin-bottom:0.4rem
}

.right-panel .sensitiviy-description {
    /*margin-top: 2rem;*/
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    margin-bottom: 0.5rem;
    font-size: 0.6rem;
    font-weight: 300;
    line-height: 0.8rem;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .tabs {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    display: flex;
}

.right-panel .tabs div {
    height: 3rem;
    max-width: 8vw;
    border-radius: 0.2rem;
    background: #102646;
    color: #8095C6;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    flex-grow: 1;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.right-panel .tabs3 {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
}

.right-panel .tabs3 div {
    height: 3rem;
    width: 30%;
    border-radius: 0.2rem;
    background: #102646;
    color: #8095C6;
    margin-left: 2%;
    margin-right: 1%;
    flex-grow: 1;
    line-height: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.right-panel .tab-header:hover {
	cursor: pointer;
	box-shadow: 0 0 10px 1px;
}

.right-panel .tabs3 div:nth-child(3) {
    margin-right: 2%;
}

.right-panel .tabs div.active {
    background: #FFFFFF;
    color: #0E1D40;
}

.right-panel .tabs3 div.active {
    background: #FFFFFF;
    color: #0E1D40;
}

.right-panel .species-tab {
    height: 50%;
    max-height: 50%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin-bottom: 0.5rem;
}

::-webkit-scrollbar {
    width: 0px;
  }
  
.right-panel .species-tab-body {
    margin-bottom: 0.5rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.right-panel .species-header {
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
    width: 96%;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-template-columns: 7fr 5fr;
    row-gap: 0.75rem;
    align-items: center;
}

.right-panel .species-row {
    position: relative;
    cursor: pointer;
    width: 96%;
    margin: auto;
    border-radius: 0.3rem;
    background: #102646;
    color: #FFFFFF;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem;
    letter-spacing: 0em;
    text-align: left;
    display: grid;
    grid-template-columns: 3.5rem 3fr 2fr 1fr;
    grid-column-gap: 0.2rem;
    align-items: center;
}
.right-panel .species-offshore {
    grid-template-columns: 3.5rem 3fr 2fr 2fr;
}
.right-panel .species-onshore {
    grid-template-columns: 3.5rem 3fr 2fr 2fr;
}
.right-panel .species-pwdist {
    grid-template-columns: 3.5rem 3fr 2fr 2fr 2fr;
}
.right-panel .species-pwtran {
    grid-template-columns: 3.5rem 3fr 2fr 2fr;
}

.right-panel .species-header-offshore {
    grid-template-columns: 4.5fr 2fr 2fr;
}
.right-panel .species-header-onshore {
    grid-template-columns: 4.5fr 2fr 2fr;
}
.right-panel .species-header-pwdist {
    grid-template-columns: 4fr 2fr 2.5fr 2fr;
}
.right-panel .species-header-pwtran {
    grid-template-columns: 4.5fr 2fr 2fr;
}
.right-panel .sort-arrow {
    margin-left: 5px;
    width: 10px;
    height: 5px;
}

.right-panel .species-endanger-classification {
    font-family: 'Work Sans';
    font-size: 0.7rem;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
    background-color: #152F54;
    color: #8095C6;
    border-radius: 1.8rem;
    height: 2.7rem;
    width: 2.7rem;
    padding: 0.3rem;
    margin: auto;
    align-content: center;
}

.right-panel .species {
    border-radius: 1.8rem;
    height: 3.2rem;
    width: 3.2rem;
    padding: 0.3rem;
    margin-top: auto;
    margin-bottom: auto;
}

.right-panel .common-name {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 0.3rem;
}

.right-panel .spec-name {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.right-panel .latin-name {
    font-size: 0.55rem;
    font-style: italic;
    font-weight: 300;
    line-height: 0.55rem;
    letter-spacing: 0em;
    text-align: left;
}

.right-panel .separation-row {
    height: 0.5rem;
}

.right-panel .land-cover-panel {
    position: relative;
    overflow-y: auto;
    max-height: 45%;
    max-width: 95%;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.right-panel .land-cover-panel .land-cover-labels {
    /*position: relative;
    top: -300px;*/
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    padding: 0.5rem;
    max-width: 38%;
    margin: auto;
    line-height: 0.7rem;
    font-size: 0.7rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 7px;
}

.right-panel .sites-tab {
    max-height: 54%;
}

.right-panel .sites-tab-body {
    overflow-y: scroll;
}

.padding-left-5 {
    padding-left: 5px;
}

.right-panel .sites-header {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
    display: grid;
    grid-template-columns: 7fr 5fr;
    row-gap: 0.75rem;
    align-items: center;
    width: 94%;
    margin: auto;
    padding-top: 0.5rem;
}

.right-panel .sites-title {
    padding-left: 0.6rem;
    padding-right: 0.3rem;
}

.right-panel .site-text {
    padding: 0.7rem;
}

.right-panel .feature-text {
    padding: 0.3rem;
}

.right-panel .sites-row {
    height: 3rem;
    width: 96%;
    margin: auto;
    border-radius: 0.3rem;
    background: #102646;
    color: #FFFFFF;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem;
    letter-spacing: 0em;
    text-align: left;
    display: grid;
    grid-template-columns: 7fr 5fr;
    row-gap: 0.75rem;
    align-items: center;
}

.right-panel .features-row {
    width: 96%;
    margin: auto;
    border-radius: 0.3rem;
    background: #102646;
    color: #FFFFFF;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem;
    letter-spacing: 0em;
    text-align: left;
    display: grid;
    grid-template-columns: 4rem auto;
    row-gap: 0.75rem;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.right-panel .sensitive-features-panel {
    font-size: 0.6rem;    
}

.right-panel .sensitive-features-tab-header {
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem;
    letter-spacing: 0em;
    text-align: left;
    color: #8095C6;
    padding: 0.7rem;
}

.right-panel-minimized {
    z-index: 3;
    width: 34px;
    height: 34px;
    position: absolute;
    top: 30px;
    right: 20px;
    /*
    top: 3vh;
    right: 3vh;
    */
    background-color: rgba(1, 16, 51, 0.93);
    border-radius: 17px;
}

.right-panel-minimized .collapse {
    height: 34px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.right-panel-minimized .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.landcover-urban {
    color: #D73027;
}

.landcover-agricultural {
    color: #F46D43;
}

.landcover-shrub {
    color: #FDAE61;
}

.landcover-herbaceous {
    color: #FEE090;
}

.landcover-bare {
    color: #FFFFBF;
}

.landcover-forest {
    color: #8AD293;
}

.landcover-moss {
    color: #E0F3F8;
}

.landcover-wetlands {
    color: #ABD9E9;
}

.landcover-water {
    color: #74ADD1;
}

.landcover-snow {
    color: #5692E1;
}

.green {
    color: green;
}

.yellow {
    color: yellow;
}

.orange {
    color: orange;
}

.red {
    color: red;
}

.sensitive-site-tab {
    background: #E92F2F !important;
    color: #FFFFFF !important;
}

.right-panel .sensitive-site-tab:hover {
	box-shadow: none;
}

.right-panel > div.progress {
    width: 100%;
    border-radius: 16px;
}

.font9 {
    font-size: 9px !important;
}

/* The actual popup */
.popuptext {
    display: none;
    width: 198px;
    /*height: 94px;*/
    top: 8px;
    left: 60px;
    background-color: #FFFFFF;
    color: #000000;
    text-align: left;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
}
.popuptext .endanger-header {
    font-size: 0.7rem;
}
.popuptext .endanger-body {
    font-size: 0.6rem;
}
.popuptext::after {
    display: none;
}

/* Toggle this class - hide and show the popup */
.show .popuptext {
    display: block;
}

.right-panel .hide-image .species {
    display: none;
}

.right-panel .hide-image .endanger-classification {
    display: flex;
}

.right-panel .endanger-classification {
    display: none;
}

.shareIconPopup {
    display: none;
    font-size: 10px;
    width: 100px;
    height: 20px;
    line-height: 16px;
    top: 0;
    left: -100px;
    background-color: #FFFFFF;
    color: #000000;
    text-align: center;
    border-radius: 2px;
    padding: 2px;
    position: absolute;
    z-index: 1;
}

.showShareIconPopup {
    display: block;
}