.country-root {
    display: grid;
    grid-template-rows: 20fr 80fr;
    overflow-y: scroll;
}

.country-list {
    display: grid;
    grid-template-rows: auto;
    row-gap: 1rem;
    padding: 0.5rem 1rem;
    max-height: 60vh;
    overflow-y: scroll;
}

.country-label {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding-left: 1rem;
    text-align: left;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 100%;
    color: #FFFFFF;
}

.countrycard-root {
    background-color: rgba(16, 37, 75, 0.93);
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    color: rgba(161, 187, 247, 1);
    border: 1px solid transparent;
}

.countrycard-root:hover {
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    box-shadow: 0 0 10px 1px #506B9C;
}

.countrycard-label {
    padding-left: 1rem;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 100%;
    color: #A1BBF7;

}

.countrycard-flag {
    padding-right: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.4rem;
}