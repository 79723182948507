.l-header {
    background-color: rgba(16, 37, 75, 0.93);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    width: 100%;
    display: grid;
}

.l-header-root {
    font-size: 15px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: left;
    column-gap: 1rem;
    margin-left: 1rem;
    margin-right: 0.2rem;
    height: 60px;
}

.l-header-menu {
    color: inherit;
    display: grid;
}
.l-header-menu:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}
.l-header-title {
    display: flex;
}
.l-header-title .avistep-logo {
    height: 26px;
	width: 104px;
	margin-top: auto;
	margin-bottom: 9px;
}

.MuiInput-underline::after {
    border-bottom: none;
}