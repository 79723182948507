.row::after {
  content: '';
  clear: both;
  display: table;
}

[class*='col-'] {
  float: left;
  padding: 0.7rem;
}

.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

.about {
  min-height: 140vh;
  width: 100%;
  /*background-image: linear-gradient(to bottom right, #0F2044, #016098);*/
  background-image: linear-gradient(
    to bottom,
    #065d92,
    #112d53,
    #102448,
    #0e2c53,
    #04568b,
    #0b3e6a,
    #112246
  );
  position: relative;
  top: -10px;
  margin-bottom: -20px;
}

.about .head {
  position: sticky;
  top: 0;
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: space-between;
  color: white;
  margin-left: auto;
  margin-right: auto;
  background-color: #0f2044;
  z-index: 1;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(11px);
}

.about .logocontainer {
  font-family: 'Hind Madurai';
  font-size: 30px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.08em;
  padding-left: 25px;
  cursor: pointer;
  display: flex;
}

.about .avistep-logo {
  height: 39px;
  width: 145px;
  margin-top: auto;
  margin-bottom: 25px;
}

.about .button-group {
  display: inline-flex;
  flex-direction: row;
  position: relative;
  top: 20px;
  max-width: 58%;
}

.about .infocontainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
    to bottom,
    #065d92,
    #112d53,
    #102448,
    #0e2c53,
    #04568b,
    #0b3e6a,
    #112246
  );
}

.about .infopanel {
  width: 100%;
  color: white;
  /*
	background-color: #0F2044;
	background-image: linear-gradient(to bottom right, #0F2044, #016098);
	*/
}

.about .acknowpanel {
  width: 100%;
  color: white;
  /*
	background-color: #016098;
	background-image: linear-gradient(to bottom right, #016098, #0F2044);
	*/
}

.about .contactpanel {
  min-height: 70vh;
  width: 100%;
  color: white;
  /*
	background-color: #0F2044;
	background-image: linear-gradient(to bottom right, #0F2044, #016098);
	*/
}

.about .btnlink {
  border: 1px solid white;
  padding: 15px 50px 15px 50px;
  border-radius: 0px;
  color: white;
  background-color: transparent;
  margin: 0;
  width: 100%;
}

.about .btnlink:first-child {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.about .btnlink:last-child {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

.about .btnlink:hover {
  background-color: white;
  color: #06234c;
}

.about > div.head > div > button {
  max-width: 260px;
}

.about .btn_map {
  height: 40px;
  background-color: #008bcd;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  left: -10px;
  top: 3px;
}

.about .btn_map:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 1px;
}

.about .general {
  font-family: 'Work Sans';
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0em;
  margin-top: 15px;
  text-align: left;
}

.about h1 {
  font-family: 'Hind Madurai';
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}

.general h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
}

.general p {
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
}

.general .about-p {
  font-size: 16px;
}

.general .image-set-1 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.general .image-set-1::after {
  content: '';
  clear: both;
  display: table;
}

.general .image-set-1 div.intro-pragraph {
  width: 50%;
  float: left;
  /*padding: 15px;*/
}

.general .image-set-1 div.image-container {
  width: 50%;
  float: left;
  padding-top: 50px;
  /*padding: 15px;*/
}

.general .image-set-1 > div:nth-child(1) {
  /*padding-top: 40px;*/
  padding-right: 20px;
}

.general .image-set-1 > div:nth-child(2) {
  text-align: right;
  padding-right: 0;
}

.general .image-set-1 .image-1 {
  /*width: 647px;*/
  max-width: 85%;
  height: auto;
  /*max-height: 500px;*/
}

.image-container {
  position: relative;
}

.image-caption {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  bottom: 38px;
  font-size: 14px;
  right: 0;
  padding-right: 10px;
  opacity: 0;
  transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  text-align: right;
}

.general .image-set-1 .image-caption {
  /*width: 647px;*/
  width: 85%;
  position: absolute;
  bottom: 8px;
}

.general .image-set-2 .image-caption {
  /*width: 1561px;*/
  width: 100%;
}

.image-container:hover .image-caption {
  opacity: 1;
}

.general .image-set-2 {
  /*width: 100%;*/
  /*width: 1561px;*/
  padding-top: 60px;
  padding-bottom: 30px;
  margin: auto;
  text-align: center;
}

.general .image-set-2 .image-2 {
  width: 100%;
  height: auto;
}

.general .group-set-3 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.general .group-set-3::after {
  content: '';
  clear: both;
  display: table;
}

.general .group-set-3 div {
  /*background: #E9E9E9;*/
  border-radius: 6px;
  font-family: 'Work Sans';
  font-size: 25px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  vertical-align: middle;
}

.general .avistep-borchure {
  height: 100%;
  width: auto;
}
.general .avistep-brochure-label {
  color: white;
}

.general .group-set-3 div:nth-child(1) {
  height: 480px;
  width: 100%;
  padding: 15px;
  text-align: center;
  padding-bottom: 50px;
}

.general .group-set-3 div:nth-child(2) {
  width: 100%;
  padding: 15px;
  height: 434px;
  padding-bottom: 50px;
}

.general .group-set-3 iframe {
  width: 700px;
  height: 394px;
}

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.general .download-icon {
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.general .ebook-icon {
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 10px;
  left: 55px;
}

.foot-note {
  font-family: 'Work Sans';
  font-size: 14px;
  font-weight: 400;
  /*line-height: 14px;*/
  letter-spacing: 0em;
  text-align: justified;
  padding-bottom: 20px;
  /*padding-top: 50px;*/
  line-height: 30px;
}

.acknowledgement {
  font-family: 'Work Sans';
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0em;
  margin-top: 20px;
}

.acknowledgement h2 {
  font-weight: 700;
}

.acknowledgement h3 {
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.acknowledgement h5 {
  font-size: 20px;
  font-weight: 300;
  line-height: 0;
  padding-top: 30px;
  letter-spacing: 0em;
}

.acknowledgement div {
  font-size: 16px;
  font-weight: 300;
  line-height: 29px;
}

.acknowledgement .image-set-1 {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 30px;
}

.acknowledgement .image-set-1::after {
  content: '';
  clear: both;
  display: table;
}

.acknowledgement .image-set-1 div {
  width: 50%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.acknowledgement .image-set-1 .image-1 {
  width: 100%;
  /*width: 755px;*/
  height: auto;
}

.acknowledgement a {
  color: white;
  text-decoration: none;
}

.acknowledgement .image-set-1 .image-caption {
  width: 100%;
  /*width: 755px;*/
}

.acknowledgement .image-set-1 > div:nth-child(2) {
  text-align: right;
}

.acknowledgement .image-set-1 > div:nth-child(2) {
  text-align: right;
  /*padding: 10px;*/
}

.acknowledgement .image-set-1 .image-2 {
  width: 100%;
  /*width: 755px;*/
  height: auto;
}

.acknowledgement .image-set-2 {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 30px;
  margin: auto;
  text-align: center;
}

.acknowledgement .image-set-2 .image-3 {
  width: 100%;
  height: auto;
}

.acknowledgement .logo-grid {
  width: 100%;
  padding: 60px 60px;
}

.acknowledgement .logo-grid .row {
  width: 100%;
}

.acknowledgement .logo-grid .row::after {
  content: '';
  clear: both;
  display: table;
}

.acknowledgement .logo-grid .row div {
  width: 20%;
  float: left;
  padding: 15px;
  text-align: center;
}

.acknowpanel .logo-grid .row div img {
  height: auto;
  max-width: 100%;
}

.acknowledgement .partner-logo {
  position: relative;
}

.contact {
  margin-top: 20px;
}

.contact .contactSectionHeader {
  display: block;
  margin-top: 20px;
  width: 100%;
  margin-left: 20px;
  font-family: Hind Madurai;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
}

.contact .contactUsSection {
  display: flex;
  margin-top: 20px;
}

.contact .contactUsFormColumn {
  flex: 50%;
  width: 80%;
  margin-left: 20px;
  margin-right: 20px;
}

.contact .contactInfoColumn {
  flex: 50%;
  width: 80%;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
}

.contact .contactInfoSection {
  margin-top: 20px;
}

.contact .contactInfoSectionHeader {
  text-align: left;
  font-weight: bold;
  text-transform: uppercase;
}

.contact .contactInfo {
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.contact input {
  font-family: 'Work Sans';
  text-align: left;
  color: white;
  width: 100%;
  background: rgb(255, 255, 255, 0);
  border: 1px white inset;
  font-size: 16px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 18px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.contact input::placeholder {
  color: white;
  opacity: 1;
}

.contact input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.contact input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}

.contact .message {
  font-family: 'Work Sans';
  text-align: left;
  color: white;
  width: 100%;
  height: 7em;
  background: rgb(255, 255, 255, 0);
  border: 1px white inset;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.contact .message::placeholder {
  color: white;
  opacity: 1;
}

.contact .message:-ms-input-placeholder {
  color: white;
}

.contact .message::-ms-input-placeholder {
  color: white;
}

.contact .submitButton {
  text-align: center;
  color: white;
  width: 100%;
  background: rgb(255, 255, 255, 0);
  border: 1px white inset;
  font-size: 18px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 50px;
  height: 50px;
}

.contact .submitButton:hover {
  box-shadow: 0px 0px 10px 1px white;
}

.contact .errorIcon {
  height: 16px;
  padding-right: 5px;
  padding-top: 5px;
}

.contact .errorMessage {
  position: relative;
  top: -20px;
  line-height: 1;
  padding: 0;
  margin: 0;
  text-align: left;
  color: white;
  font-size: 14px;
}

.modal-background {
  /*background-color: rgba(0,0,0,0.8);*/
  background-color: rgba(12, 27, 61, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.modal-container {
  position: relative;
  top: 380px;
  width: 600px;
  height: 325px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  color: #0c1b3d;
}

.exit-button {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 16px;
  height: 16px;
  /*    position: relative;
    top: -15px;
    left: -290px;
	*/
}

.modal-icon {
  width: 73px;
  height: 73px;
}

.modal-header {
  font-family: Work Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}

.modal-text {
  font-family: Work Sans;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 26px;
}

.outline-letters {
  color: transparent !important;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-stroke-width: 1px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: 'Hind Madurai';
  font-size: 45px;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: left;
}

.light-letters {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 200;
}

.general p.subsection-header {
  font-family: 'Hind Madurai';
  font-size: 30px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 1205px;
}

.super-script {
  vertical-align: super;
  font-size: smaller;
}

.relative {
  position: relative;
}

.help {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  font-size: 16px;
}

.help .small-paragraph {
  max-width: 775px;
  line-height: 36px;
  font-size: 16px;
  padding-bottom: 60px;
}

.help p {
  line-height: 30px;
}

.help h3 {
  padding-top: 30px;
}

.help div.instructional-video {
  text-align: center;
}

.help div.instructional-video iframe {
  width: 700px;
  height: 394px;
}

.help .avistep-technical-manual-container {
  max-width: 480px;
  height: 670px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 50px;

  font-family: 'Work Sans';
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.help .avistep-technical-manual-container a {
  color: white;
}

.help .avistep-technical-manual {
  height: 100%;
  width: auto;
}

.help .container .row > div:nth-child(1) {
  padding-left: 0;
  padding-right: 50px;
}

.help .container .row .right-border {
  border-right: 1px solid;
  border-right-color: rgba(255, 255, 255, 0.25);
}

.help .container .row > div:nth-child(2) {
  padding-left: 100px;
  padding-right: 0px;
}

.help .container .row {
  padding-bottom: 100px;
}

.help ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 50px;
}

.help li {
  line-height: 60px;
  position: relative;
}

.help li .icon {
  position: relative;
  vertical-align: middle;
  padding-right: 10px;
}

.help .sensitivity-card {
  height: 60px;
  width: 229px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
}

.help .sensitivity-card:hover {
  transition: none;
  border: none;
  /*
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
	*/
  pointer-events: none;
}

.help .avistep-manual-place-holder {
  height: 392px;
  width: 308px;
  border-radius: 6px;
  background-color: #e9e9e9;
  text-align: center;
  margin: auto;
  color: #000000;
}

.help .avistep-manual-place-holder div {
  text-align: center;
  color: #000000;
  vertical-align: middle;
  font-size: 25px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}

.help .container .row > div.col-9.relative {
  height: 392px;
  max-width: 775px;
}

.help .category-card-container {
  margin-top: 60px;
  margin-bottom: 100px;
}

.help .category-card {
  height: 107px;
  max-width: 912px;
  padding-left: 20px;
  border-radius: 8px;
  background-color: #10264699;
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 50px;
}

.help .category-card-container .row.category-card div:nth-child(1) {
  font-size: 18px;
}

.help .category-card-container .row.category-card > div:nth-child(2) {
  font-size: 16px;
  line-height: 28px;
  padding-left: 0;
}

.help .category-card:nth-child(1) {
  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: #55ff00;
}

.help .category-card:nth-child(2) {
  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: #ffff00;
}

.help .category-card:nth-child(3) {
  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: #ffaa00;
}

.help .category-card:nth-child(4) {
  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: #ff0000;
}

@media only screen and (min-width: 1280px) {
  .about .logocontainer {
    padding-left: 50px;
  }

  .about .button-group {
    max-width: 60%;
  }

  .about .btn_map {
    font-size: 16px;
    padding-right: 30px;
    padding-left: 30px;
    left: -20px;
  }

  .about .btn_map {
    height: 50px;
    line-height: 0px;
  }

  .about .general {
    padding-left: 100px;
    padding-right: 100px;
  }

  .about h1 {
    font-size: 50px;
    line-height: 75px;
  }

  .general h3 {
    font-size: 32px;
    line-height: 41px;
  }

  .general .about-p {
    font-size: 20px;
  }

  .general .image-set-1 {
    padding-top: 100px;
    padding-bottom: 35px;
  }

  .general .image-set-1 > div:nth-child(1) {
    padding-right: 100px;
  }

  .general p.subsection-header {
    font-size: 45px;
    line-height: 70px;
  }

  .general .group-set-3 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .general .group-set-3 div:nth-child(1) {
    width: 30%;
    float: left;
    padding-bottom: 15px;
  }

  .general .group-set-3 div:nth-child(2) {
    width: 60%;
    float: left;
    padding-bottom: 15px;
  }

  .general .group-set-3 iframe {
    width: 800px;
    height: 450px;
  }

  .help {
    margin-top: 100px;
    margin-bottom: 100px;
    padding-left: 100px;
    padding-right: 100px;
  }

  .help h3 {
    padding-top: 100px;
  }

  .help div.instructional-video iframe {
    width: 1050px;
    height: 591px;
  }

  .acknowledgement {
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 100px;
  }

  .acknowledgement h5 {
    font-size: 24px;
  }

  .contact {
    margin-top: 100px;
  }

  .contact .contactSectionHeader {
    margin-top: 100px;
    margin-left: 100px;
    font-size: 50px;
    line-height: 65px;
  }

  .contact .contactUsSection {
    margin-top: 50px;
  }

  .contact .contactUsFormColumn {
    margin-left: 100px;
    margin-right: 50px;
  }

  .contact .contactInfoColumn {
    margin-left: 150px;
    margin-right: 50px;
  }

  .contact .contactInfoSection {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1550px) {
  .about .general {
    padding-left: 150px;
    padding-right: 150px;
  }

  .help {
    padding-left: 150px;
    padding-right: 150px;
  }

  .acknowledgement {
    padding-left: 150px;
    padding-right: 150px;
  }

  .contact .contactSectionHeader {
    margin-left: 150px;
  }

  .about .btn_map {
    font-size: 18px;
  }

  .general .image-set-1 > div:nth-child(1) {
    padding-top: 40px;
  }

  .general .image-set-2 {
    padding-top: 100px;
    /*padding-bottom: 100px;*/
  }

  .general p {
    font-size: 18px;
  }

  .help {
    margin-top: 120px;
  }

  .help .small-paragraph {
    padding-bottom: 120px;
  }

  .help .small-paragraph {
    font-size: 20px;
  }

  .help {
    font-size: 18px;
  }

  .acknowledgement {
    margin-top: 120px;
  }

  .acknowledgement .image-set-1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .acknowledgement .image-set-2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .acknowledgement .logo-grid {
    padding: 100px 100px;
  }

  .contact {
    margin-top: 120px;
    padding-bottom: 50px;
  }

  .contact .contactUsFormColumn {
    flex: 35%;
    margin-left: 150px;
  }

  .contact .contactInfo {
    font-size: 20px;
  }
}
