.map {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;  
  text-align: center;
  z-index: 0;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
  outline:none;
 }

.logo {
  position: absolute;
  z-index: 3;
  top: 2rem;
}

.progress {
  position: absolute;
  z-index: 4;
  top:0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(16, 37, 75, 0.5);
  display:grid;
  align-items: center;
  justify-items: center;
}
