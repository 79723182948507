.pdf-root {
    position: absolute;
    top: 5vh;
    left: 25vw;
    width: 50vw;
    height:90vh;
    z-index: 10;
    border-radius: 16px;
    background-color: rgba(16, 37, 75, 1);
    align-content: center;

}

.pdf-modalbackground {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(12,27,61,0.5);
    z-index: 9;
    align-content: center;

}

.pdf-view {
    position: absolute;
    top: 0px;
    height: 99%;
    width: 99%;
    z-index: 12;
    border-radius: 16px;
    background-color: rgba(16, 37, 75, 1);
    display: grid;
    grid-template-rows: 10% 90%;
    align-content: center;
    margin: 5px;
}

.pdf-maps {
    position: absolute;
    top: 0px;
    height: 97%;
    width: 95%;
    padding-left: 3px;
    border-radius: 16px;
    z-index: 11;
    margin: 5px;
    display: grid;
    grid-template-rows: 20% 60% 20%;
    align-content: center;
    background-color: rgb(76, 76, 68);
}
.pdf-mapcontainer {
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.pdf-header {
    padding-top: 10px;
}
.pdf-viewer {
    width: 95%;
    height: 95%;
    margin-left: auto;
    margin-right: auto;
}
.pdf-progress {
    display: flex;
    flex-direction: column;
    height: 20%;
    width: 80%;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: #FFFFFF;
}
.pdf-footer {
    display: flex;
}

.pdf-close {
    position: absolute;
    z-index: 14;
    top: 15px;
    left: 15px;
}

.pdf-close:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}