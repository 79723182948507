/* Media Query for Tablets */
.large-screen {
	display: none;
}

.small-screen {
	display: block;
	width: 100%;
	min-height: 100vh;
	padding-left: 50px;
	padding-right: 50px;
	margin: auto;
}

.small-screen > div {
	padding-top: 100px;
	width: 100%;
}

.small-screen > p.sub-title {
	font-family: 'Work Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
}

.small-screen > p.sub-title > a {
	text-decoration: none;
	color: white;
}

.avilogo {
	height: 80px;
	width: 285px;
}

@media only screen and (min-width: 768px) {
	.large-screen {
		display: block;
		width: 100%;
		height: 100%;
	}

	.small-screen {
		display: none;
	}
		
	.splash-video {
		position: relative;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}

	.transition-video {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 10;
	}

	.home {
		position: absolute;
		top: 0;
		width: 100%;
	}

	.home::after {
		content: "";
		clear: both;
		display: table;
	}

	.home .left {
		width: 45%;
		float: left;
		padding-left: 50px;
		min-height: 100vh;
		text-align: left;
		color: white;
	}

	.home .title {
		padding-top: 50px;
	}

	.home .sub-title {
		max-width: 500px;
		margin-top: 20px;
	}

	.home .button-list {
		height: 40px;
		grid-gap: 1em;
		gap: 1em;
		display: flex;
		flex-wrap: wrap;
	}

	.home .foot {
		height: 20vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.home .logo {
		max-height: 65px;
		position: relative;
	}

	.home .avilogo {
		height: 60px;
		width: 214px;
	}

	.home .right {
	max-height: 100vh;
		max-width: 48%;
		float: left;
		margin: auto;
	}


	.home .vertical-center {
	position: relative;
	top: 50%;
	transform: translateY(5%);
	}

	.home .globe {
	max-width: 600px;
		height: auto;
	position: relative;
	top:-780px;
	left: 50px;
	}

	.home h2 {
		font-weight: 700;
		font-size: 50px;
		line-height: 69px;
		letter-spacing: 0.08em;
	}

	.home h4 {
		font-family: 'Work Sans';
		font-style: normal;
		font-weight: 300;
		font-size: 20px;
		line-height: 148.52%;
		letter-spacing: 0.03em
	}

	.home .btn {
		display: inline-block;
	}

	[class*="btn-"] {
		font-family: 'Work Sans';
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: center;
		text-decoration: none;
		border-radius: 50px;
		border: 1px solid white;
		padding: 5px 20px 5px 20px;
	}

	.home .btn-white {
		background-color: white;
		color: #0F2044;
	}

	.home .btn-white:hover {
		cursor: pointer;
		box-shadow: 0 0 10px 1px white;
	}

	.home .btn-clear {
		color: white;
		background-color: transparent;
	}

	.home .btn-clear:hover {
		cursor: pointer;
		box-shadow: 0 0 10px 1px white;
	}
}

@media only screen and (min-width: 1280px) {
	.home .logo {
		max-height: 80px;
		position: relative;
	}
}

@media only screen and (min-width: 1550px) {
	.home .foot {
		height: 30vh;
	}
	
	.home .left {
		padding-left: 120px;
	}

	.home h4 {
		font-size: 32px;
	}

	.home .sub-title {
		min-height: 12vh;
		margin-top: 20px;
	}

	[class*="btn-"] {
		padding: 15px 50px 15px 50px;
	}
}

@media only screen and (min-width: 1280px) {
	.home .title {
		padding-top: 24vh;
		min-height: 20vh;
	}

	.home .left {
		padding-left: 100px;
	}

	.home .avilogo {
		height: 80px;
		width: 285px;
	}

	.home h4 {
		font-size: 25px;
	}

	.home .sub-title {
		min-height: 12vh;
	}

	[class*="btn-"] {
		font-size: 18px;
		line-height: 23px;
		padding: 12px 50px 12px 50px;
	}

	.home .button-list {
		height: 50px;
		gap: 2em;
	}
}