input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  .tog {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  
  .toglabel {
    cursor: pointer;
    text-indent: -9999px;
    width: 2rem;
    height: 1rem;
    background: #27406D;
    display: block;
    border-radius: 0.8rem;
    position: relative;
  }
  
  .toglabel:after {
    content: "";
    position: absolute;
    top: .06rem;
    left: .06rem;
    width: 0.9rem;
    height: 0.9rem;
    background: #fff;
    border-radius: 0.9rem;
    transition: 0.1s;
  }
  
  input:checked + .toglabel {
    background: #0083CD;
  }
  
  input:checked + .toglabel:after {
    left: calc(100% - .06rem);
    transform: translateX(-100%);
  }
  
  .toglabel:active:after {
    width: 1rem;
  }